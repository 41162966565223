import type { Action } from '@/utils/reCaptcha';

export const useReCaptcha = () => {
  const {
    public: { reCaptchaAPIKey },
  } = useRuntimeConfig();

  const getToken = ({ action }: { action: Action }): Promise<string> => {
    return new Promise((resolve) => {
      grecaptcha.enterprise.ready(async () => {
        resolve(
          grecaptcha.enterprise.execute(reCaptchaAPIKey, {
            action,
          })
        );
      });
    });
  };

  const scriptURL = `https://www.google.com/recaptcha/enterprise.js?render=${reCaptchaAPIKey}`;

  return {
    getToken,
    scriptURL,
  };
};
